<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.519 171.81296" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
 <g transform="translate(-38.812 -17.074)">
  <path d="m100.75 56c-8.6544 0.06938-12.588 2.256-14.562 5.4688s-2 7.8155-2 12.781c0 4.7389 1.7761 8.7535 5.0625 11.75a0.99476 0.99476 0 0 1 -0.125 1.5938c-6.8918 4.5347-8.5938 12.441-8.5938 21.719 0 4.654 1.2684 9.009 3.5 12.781a0.99476 0.99476 0 0 1 -0.34375 1.3438c-10.654 6.271-17.652 17.075-17.688 29.344 1e-3 0.0109-1e-3 0.0204 0 0.0312-4.1e-5 0.0211 0 0.0414 0 0.0625 1.0351 11.216 3.4043 19.786 9.5 25.625 6.1092 5.852 16.14 9.1418 32.938 9.375h0.0312c18.292-0.69726 28.512-1.7327 34.906-5.9375 6.3666-4.1866 9.3683-11.716 13.094-25.969-1.0573-12.402-10.392-25.827-23.094-32.969a0.99476 0.99476 0 0 1 -0.4375 -1.25c1.2907-3.3166 1.7548-7.3597 0.9375-12.281a0.99476 0.99476 0 0 1 -0.0312 -0.15625c0-9.019-4.7696-16.859-12.031-21.469a0.99476 0.99476 0 0 1 -0.18755 -1.5312c2.4424-2.6156 3.7428-6.3688 3.25-11.906-1.7147-6.2535-4.0338-10.65-7.25-13.562-3.2347-2.9288-7.4225-4.4354-13.156-4.75-1.3203-0.07246-2.5642-0.10301-3.7188-0.09375z" :fill="fill1"/>
  <path d="m96.366 55.321c-12.661 1.7409-12.594 10.05-12.594 18.938 0 10.587 8.3558 17.362 22.562 18.156 1.1246 0.06284 2.3225-0.34835 3.4007-0.43371-10.825-1.8835-19.631-7.5048-19.631-17.708 0-6.7351 2.2898-14.918 6.262-18.952z" :fill="fill2"/>
  <path d="m131.43 175.96c0 1.4857-2.4129 2.602-3.6163 2.6902-1.9919 0.146-3.6163-1.2044-3.6163-2.6902s1.1781-3.925 3.6163-2.6902c1.7818 0.90236 3.6163 1.2044 3.6163 2.6902z" fill="#312c29"/>
  <path d="m132.58 160.03c0 1.4857-1.8807 2.5761-3.6163 2.6902-2.7803 0.1827-3.6163-1.2044-3.6163-2.6902s0.82525-2.7784 3.6163-3.7486c1.8865-0.65578 2.8225 2.1747 3.6163 3.7486z" fill="#312c29"/>
  <path d="m125.61 140.41c-1.1878 0.96854-2.9676 0.94438-4.1017-0.0597-0.85995-0.76138-1.2283-1.5685 0.0397-3.4686 0.85709-1.2843 2.4898-0.59331 4.0662-0.0816 1.1871 0.62891 1.4841 1.6695-4e-3 3.6099h-2.1e-4z" fill="#312c29"/>
  <path d="m121.81 121.21c-1.1878 0.96854-2.9676 0.94438-4.1017-0.0597-0.85995-0.76138-1.7028-3.0881 0.39251-3.9978 1.7391-0.75505 1.696 0.81791 3.0077 0.88861 1.3169-0.10071 2.0864 1.6671 0.70142 3.1689h-1e-5z" fill="#312c29"/>
  <path d="m113.33 103.9c-1.1246-1.6434-0.47674-4.1309 1.8038-4.2624 0.85708-0.04943 1.7842 0.90611 2.8313 1.7706 0.87592 0.78131 0.93974 2.2845-1.0626 3.2571-1.3672 0.55959-2.8412 0.25911-3.5725-0.76532z" fill="#312c29"/>
  <path d="m100.24094,87.17614c-.56844-.79063-.24097-1.98735.91175-2.05063 .43323-.02378.90186.43593 1.43118.85185 .44274.37589.475,1.09906-.53713,1.56698-.6911.26922-1.23123.03464-1.8058-.36819z" fill="#312c29"/>
  <path d="m104.10868,88.49917c-.54102-.79063-.22936-1.98735.86778-2.05063 .41234-.02378.85836.43593 1.36214.85185 .42141.37589.45211,1.09906-.51122,1.56698-.65776.26922-1.17184.03464-1.7187-.36819z" fill="#312c29"/>
  <path d="m108.89329,89.12741c-.79729-.53116-.95544-1.75764.0386-2.22635 .37358-.17616.95908.08356 1.58179.28107 .53135.19117.83009.85046.11143,1.64449-.50949.49553-1.07398.47007-1.73177.30079z" fill="#312c29"/>
  <path d="m113.51377,88.82985c-.68295-.39978-.8722-1.4036-.075-1.83779 .29962-.16318.7949.02291 1.31789.15592 .44734.13216.72532.66151.17076,1.35077-.39638.43307-.86311.43914-1.41366.3311z" fill="#312c29"/>
  <path d="m117.7252,88.18492c-.78999-.04651-1.41397-.8553-.90042-1.60386 .19301-.28132.71881-.34013 1.24525-.45881 .45861-.08512.94645.26057.76486,1.12637-.15683.56575-.57003.78286-1.10969.93629z" fill="#312c29"/>
  <path d="m120.6887,86.66607c-.68518.09228-1.35552-.49684-1.0404-1.22462 .11843-.27352.55946-.41191.991-.60174 .37901-.14972.85515.06509.84429.83787-.0398.51135-.35781.76665-.79489.98849z" fill="#312c29"/>
  <path d="m99.537 74.287c-1.294-1.891-0.92277-4.5037 1.7013-4.6551 0.9862-0.05688 2.24 0.10709 3.445 1.1019 1.0079 0.89901 1.2684 3.3147-1.0356 4.4338-1.5732 0.64389-3.1146 0.33232-4.1106-0.88062z" fill="#312c29"/>
  <path d="m121.68 73.027c1.3428-1.3316 0.47674-4.1309-1.8038-4.2624-0.85708-0.04943-1.6594 0.34479-2.7066 1.2093-0.87592 0.78131-1.0645 2.8458 0.93789 3.8184 1.3672 0.55959 2.4358 0.072 3.5725-0.76532z" fill="#312c29"/>
  <path d="m88.719 87.137c-6.9083 4.7191-8.5938 12.948-8.5938 22.188 0 4.7693 1.2813 9.2331 3.5 13.094-10.908 6.4431-18.125 17.562-18.125 30.219 2.0774 22.711 9.5823 35.538 43.469 36l2.0938-0.0937c-25.302-3.0246-31.461-16.448-33.312-38.438 0-9.052 3.1617-17.361 8.4062-24 4.8463 5.8619 12.169 9.5938 20.375 9.5938 3.7131 0 7.4523-0.46214 10.906-1.4688-17.55-1.5528-31.281-14.89-31.281-31.125 0-5.7995 0.63347-11.28 2.5625-15.969z" :fill="fill2"/>
  <path d="m132.48 122.58c-4.7065 8.8368-15.425 12.406-25.993 12.406-7.7427 0-14.75-3.1534-19.824-8.2812 4.8605 5.8758 12.162 9.625 20.333 9.625 10.757 0 21.677-3.9188 25.484-13.75z" fill="#a0a0a0"/>
  <path d="m120.67 87.539c-4.5857 3.5436-11.397 4.3697-18.805 3.5896-3.3018-0.34774-6.2726-1.0514-8.8695-2.0082 2.7624 1.7333 6.3609 2.9429 10.726 3.4027 7.1202 0.74988 13.565-0.37302 16.948-4.9841z" fill="#a0a0a0"/>
  <path d="m100.75 55.012c-17.654 0.14153-17.562 9.3247-17.562 19.25 0 4.9941 1.922 9.3231 5.4062 12.5-7.2742 4.7863-9.0625 13.158-9.0625 22.562 0 4.8376 1.3391 9.3645 3.6562 13.281-10.949 6.4444-18.188 17.595-18.188 30.281 2.0774 22.711 9.5823 35.538 43.469 36 36.671-1.3961 41.506-3.9742 49.031-32.844-1.0265-12.877-10.59-26.577-23.625-33.906 1.3626-3.5012 1.8101-7.7461 0.96875-12.812 0-9.3709-4.9729-17.534-12.5-22.312 2.6605-2.8492 4.0594-6.974 3.5312-12.75-3.4926-12.832-9.5765-18.51-21.344-19.156-1.3399-0.07353-2.6043-0.10319-3.7812-0.09375zm0 1c1.1545-0.0093 2.3984 0.02129 3.7188 0.09375 5.7337 0.31465 9.9216 1.8212 13.156 4.75 3.2162 2.9121 5.5353 7.309 7.25 13.562 0.49279 5.5375-0.80758 9.2907-3.25 11.906a0.99476 0.99476 0 0 0 0.1875 1.5312c7.2616 4.6097 12.031 12.45 12.031 21.469a0.99476 0.99476 0 0 0 0.0312 0.15625c0.81732 4.9216 0.35323 8.9646-0.9375 12.281a0.99476 0.99476 0 0 0 0.4375 1.25c12.702 7.142 22.036 20.567 23.094 32.969-3.7255 14.252-6.7271 21.782-13.094 25.969-6.3943 4.2048-16.614 5.2402-34.906 5.9375h-0.0312c-16.798-0.23318-26.828-3.523-32.938-9.375-6.0957-5.8391-8.4649-14.409-9.5-25.625 0-0.0211-4.1e-5 -0.0414 0-0.0625-1e-3 -0.0109 1e-3 -0.0204 0-0.0312 0.036-12.269 7.0334-23.073 17.688-29.344a0.99476 0.99476 0 0 0 0.34375 -1.3438c-2.2316-3.7722-3.5-8.1272-3.5-12.781 0-9.2782 1.702-17.184 8.5938-21.719a0.99476 0.99476 0 0 0 0.125 -1.5938c-3.2864-2.9965-5.0625-7.0111-5.0625-11.75 0-4.9657 0.02584-9.5685 2-12.781s5.9081-5.3994 14.562-5.4688z" fill="#312c29"/>
  <path d="m128.33251,174.56835c0,.47482-.89213.83154-1.33707.85972-.73647.0467-1.33707-.3849-1.33707-.85972s.43557-1.25436 1.33707-.85974c.65878.28838 1.33707.38492 1.33707.85974z" fill="#8d7c6a"/>
  <path d="m130.56 158.17c0 0.58434-0.73967 1.0132-1.4223 1.058-1.0935 0.0718-1.4223-0.47369-1.4223-1.058s0.32457-1.0927 1.4223-1.4743c0.74195-0.25792 1.1101 0.85528 1.4223 1.4743z" fill="#8d7c6a"/>
  <path d="m123.43923,138.39152c-.4501.36703-1.12458.35788-1.55434-.0226-.32589-.28852-.46547-.5944.0151-1.31444 .3248-.48668.94352-.22483 1.54089-.0309 .44988.23834.56241.63267-.001,1.36799h-8e-005z" fill="#8d7c6a"/>
  <path d="m119.58071,119.1658c-.44562.36338-1.11337.35431-1.53884-.0224-.32264-.28566-.63886-1.15858.14726-1.49988 .65247-.28328.63628.30686 1.12844.33338 .49408-.0377.78275.62544.26314,1.1889z" fill="#8d7c6a"/>
  <path d="m114.25723,101.95393c-.4682-.61518-.26574-1.59232.61589-1.68798 .33135-.036.70951.31638 1.13245.63108 .35492.28578.40911.86735-.34822,1.28367-.51915.24374-1.09651.1561-1.40012-.22677z" fill="#8d7c6a"/>
  <path d="m119.31602,71.66867c.68348-.43741.57278-1.68989-.37202-1.95087-.35509-.09808-.72725-.0051-1.24456.26306-.43796.24871-.70337,1.09775.0488,1.68647 .52304.35813 1.01531.25002 1.56772.0014z" fill="#8d7c6a"/>
  <path d="m100.20052,71.89517c-.48326-.70622-.34463-1.68199.63536-1.73851 .36832-.02124.83659.03999 1.28658.41151 .37642.33575.47372,1.23792-.38675,1.65588-.58753.24047-1.16318.12411-1.53519-.32888z" fill="#8d7c6a"/>
  <path d="m114.06 75.709c1.5075-0.75629 7.2932 2.7424 13.894 3.9602 4.8075 0.83598 14.238 1.4122 14.114 3.3784-0.26275 1.5127-1.0202 1.841-2.1501 1.8768-5.9049-0.99769-11.507-1.6829-17.808-2.194-2.6215-0.02357-7.5071 0.61823-9.2155-0.14864-2.3926-2.3504-1.2516-5.1227 1.1663-6.8728z" :fill="fill2"/>
  <path d="m112.84 77.287c-1.1896 1.5961-1.3829 3.5571 0.36741 5.2817 1.6738 0.75355 6.4636 0.09967 9.032 0.12283 6.1736 0.50218 11.636 1.1692 17.421 2.1495 1.107-0.03519 1.8551-0.35597 2.1126-1.8424 4e-3 -0.06037 0.0141-0.09578 0-0.15354-0.36887 0.71698-0.944 0.92332-1.7146 0.95193-5.6849-1.125-11.079-1.911-17.145-2.4873-2.5238-0.02658-7.2341 0.71117-8.8789-0.15354-1.0806-1.2432-1.3865-2.5846-1.1941-3.8691z" :fill="fill2"/>
  <path d="m113.875,75.34375-.0312.03125h-.0312c-1.26602.91636-2.2127,2.10585-2.53125,3.4375s.0687,2.80993 1.34375,4.0625l.0312.03125 .0625.03125c1.0222.45884 2.66143.46836 4.4375.40625 1.76485-.06172 3.63086-.22809 4.90625-.21875h.0312c6.27829.50999 11.86658,1.19344 17.75,2.1875h.0312 .0625c.60082-.01904 1.16253-.11801 1.625-.46875s.76462-.90334.90625-1.71875v-.03125-.03125c.0256-.40531-.21844-.7482-.53125-1s-.72461-.44473-1.21875-.625c-.98828-.36055-2.29909-.6573-3.78125-.90625-2.96433-.4979-6.53069-.83692-8.90625-1.25-3.25354-.60031-6.32084-1.7862-8.78125-2.71875-1.2302-.46627-2.33236-.87623-3.21875-1.125s-1.57722-.38423-2.15625-.09375zm.375.71875c.17474-.08766.73382-.07633 1.5625.15625s1.89528.62766 3.125,1.09375c2.45944.93218 5.59055,2.13246 8.9375,2.75 2.43198.42289 5.98447.79049 8.90625,1.28125 1.46089.24538 2.761.51714 3.65625.84375 .44762.1633.80208.34069 1,.5 .17696.14245.18387.23352.1875.3125-.12274.65592-.30604,1.02425-.5625,1.21875-.25109.19043-.60569.28983-1.09375.3125h-.0625c-5.89378-.99401-11.49207-1.67742-17.78125-2.1875-1.34563-.0121-3.21954.12633-4.96875.1875-1.72266.06025-3.32677-.0162-4.03125-.3125-1.06731-1.07846-1.31615-2.15841-1.0625-3.21875 .25543-1.06778 1.05544-2.10786 2.1875-2.9375z" fill="#312c29"/>
  <path d="m123.65246,78.85549c-1.2831,0-2.37419.51218-2.875,1.25 .8399-.55705 2.25774-.9375 3.84375-.9375 .2679,0 .52573.04251.78125.0625-.5017-.22359-1.1034-.375-1.75-.375z" fill="#312c29"/>
  <path d="m125.08205,79.08694c-1.28309,0-2.37418.55691-2.875,1.35915 .83991-.60569 2.25774-1.01936 3.84375-1.01936 .2679,0 .52573.04622.78125.06796-.5017-.24311-1.1034-.40774-1.75-.40774z" fill="#312c29"/>
  <path d="m112.76207,79.94747c.005,1.28309.52046,2.37238 1.26003,2.87063-.55999-.83797-.94538-2.25446-.9509-3.84046-.00089-.26789.0406-.52588.0598-.78146-.22186.50247-.37116,1.1047-.36887,1.7513z" fill="#312c29"/>
  <path d="m113.61515,80.41595c.0735,1.28099.64734,2.34095 1.41264,2.79868-.60426-.80663-1.06529-2.20034-1.15613-3.78374-.0153-.26745.0123-.52731.0177-.78354-.1945.51368-.31119,1.12307-.27412,1.76861z" fill="#312c29"/>
  <path d="m114.49888,80.83858c.0709,1.18142.6421,2.16851 1.40578,2.60438-.6021-.75428-1.05994-2.04674-1.14756-3.50706-.0148-.24667.0133-.48553.0192-.72147-.19534.46959-.31312,1.0288-.27737,1.62416z" fill="#312c29"/>
 </g>
</svg>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'snowman',

  mixins: [ThemeMixin]
}
</script>
